:root {
  --my-color: rgb(66, 146, 198);
}
html {
  height: 100%;
  overflow: auto;
}
body {
  height: 100%;
  background-image: linear-gradient(#78bde2, white, #78bde2);
}

#root {
  align-items: center;
  display: flex;
  flex-direction: column;
}

#nav-tabs {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: auto;
  height: 8vh;
  z-index: 99;
  overflow: hidden;
}
.nav-tab {
  background: var(--my-color);
  color: white;
  padding: 6px 10px;
  max-height: 30px;
  box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.2);
}
.selected-tab {
  background: rgb(240, 240, 240);
  color: #444;
  padding-bottom: 11px;
  margin-top: 1px;
}

.user-dashboard {
  overflow-y: scroll;
  height: 75vh;
  max-height: 600px;
  grid-column: 1/3;
  display: flex;
}
.sign-in {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  height: 80vh;
  max-height: 600px;
  overflow-y: scroll;
  padding: 20px;
}
.webkit-scroll {
  -webkit-overflow-scrolling: touch;
}
.sign-in .card {
  width: 100%;
  max-width: 500px;
}
.link-text {
  color: var(--my-color);
  font-size: 16px;
  cursor: pointer;
}
.link-text:hover {
  font-style: italic;
  text-decoration: underline;
}
.about-app {
  height: 1px;
  overflow: hidden;
  padding: 8px;
}
.show-about-app {
  animation-name: showAboutApp;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}
@keyframes showAboutApp {
  0% {
    height: 1px;
  }
  95% {
    height: 250px;
  }
  100% {
    height: auto;
  }
}
.sign-out,
.stats-container {
  justify-content: center;
  padding: 30px;
  width: 50%;
}

.sign-in-help {
  padding: 10px 20px;
}

.username-login,
.social-login {
  overflow: hidden;
  width: 100%;
}
.username-login {
  display: none;
}
.signin-animation {
  animation-name: signInAnimation;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
}

@keyframes signInAnimation {
  40% {
    width: 1px;
  }
  50% {
    width: 1px;
    padding: 0px 0px;
  }
  100% {
    width: 100%;
    padding: 10px 20px;
  }
}

.error-text {
  color: red;
}

.policy-container,
.support-container {
  padding: 10px;
  position: unset !important;
}
.dash-policy-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.policy-container > div {
  padding: 10px;
  display: inline;
}

.policy-link {
  cursor: pointer;
}
.policy-link:hover {
  font-style: italic;
  font-weight: bold;
}

#privacypolicy,
#termsofservice,
#support-project,
#report-bug {
  position: absolute;
  top: 2%;
  left: 50%;
  height: 96%;
  max-height: 800px;
  width: 96%;
  max-width: 700px;
  padding-top: 20px;
  background: #ffffff;
  display: none;
  border: dashed 1px black;
  border-radius: 10px;
  overflow-y: scroll;
}
#privacypolicy > iframe,
#termsofservice > iframe {
  height: 90%;
  width: 90%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
.show-policy {
  display: block !important;
  animation-name: showPolicy;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes showPolicy {
  from {
    transform: translate(-50%, 0) scale(0.1);
    z-index: 99;
  }
  to {
    transform: translate(-50%, 0) scale(1);
    z-index: 99;
  }
}

.close-policy {
  cursor: pointer;
}

#support-project,
#report-bug {
  padding: 20px;
}

.thanks-gif {
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
}

.user-dashboard h5 {
  text-align: center;
  text-transform: uppercase;
  margin-top: 0;
}

.sign-in-button {
  margin: 10px 0;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.google > a,
.facebook > a {
  color: white;
}
.google > a > img,
.facebook > a > img {
  height: 60px;
  transform: translate(-30px, -10px);
  opacity: 0.5;
  position: absolute;
  left: 40px;
}

.sign-in-button > i {
  transform: translate(-10px, 0px) scale(4);
  opacity: 0.5;
  position: absolute;
  left: 40px;
}

.stat-outer-container {
  border: dashed 1px black;
  padding: 5px 10px 10px 10px;
}

.section-title-container {
  width: 100%;
}
.stat-section-title {
  font-weight: bold;
  border-bottom: solid 1px black;
  display: inline-block;
  padding: 0 25px 0 0;
  margin-bottom: 4px;
}
.stat-bars-container {
  display: flex;
}
.incomplete-bars-container,
.complete-bars-container {
  display: flex;
  border-radius: 8px;
  overflow: hidden;
}
.stat-bars {
  height: 20px;
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: rgba(255, 255, 255, 0.5);
}

.profile-info {
  padding: 20px;
}
.profile-info > img {
  margin-left: 50%;
  transform: translate(-50%, 0);
  border-radius: 6px;
}
.profile-info > button {
  margin-left: 50%;
  transform: translate(-50%, 0);
}
.profile-info > div {
  background-color: rgba(155, 155, 155, 0.2);
  padding: 5px;
  border: dashed 1px black;
  border-radius: 3px;
  margin: 4px 0;
}
.profile-info .btn-large i {
  font-size: 3rem;
}
.profile-info .btn-large {
  margin-left: 50%;
  transform: translate(-50%, 0);
}

#links-icons {
  display: flex;
  flex-wrap: wrap;
}
#links-icons > div {
  margin: 0 5px;
}
#links-icons .links-icons {
  height: 30px;
  width: 30px;
  border-radius: 5px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.6);
  overflow: hidden;
}
.delete-social-icons {
  transform: translate(12px, -35px);
  height: 25px;
  width: 25px;
  background: white;
  text-align: center;
  border-radius: 50%;
  border: solid 1px black;
  cursor: pointer;
}
.delete-social-icons:hover {
  background: var(--my-color);
  color: white;
  border-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
}

.logout-button {
  margin-left: 50%;
  margin-top: 10px;
  transform: translate(-50%, 0);
}
.logout-button > a,
.logout-button > a > label {
  cursor: pointer;
  color: white;
}
#main h4 {
  padding-top: 15px;
  height: 10vh;
}
h5.food-titles,
#main h4 {
  color: #444;
  text-align: center;
  margin: 0;
  border-bottom: dashed 1px black;
  cursor: pointer;
}
h5.food-titles:hover,
#main h4:hover {
  color: var(--my-color);
}
h5.food-titles {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8%;
}
#home {
  height: 80vh;
}
#main {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  box-sizing: border-box;
  width: 700px;
  background-color: rgb(240, 240, 240);
  margin-bottom: 20px;
}

#books-and-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 75vh;
  max-height: 600px;
  width: 100%;
  overflow: hidden;
}
.book-list {
  position: relative;
  margin: 0 0 0 -10px;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  padding: 0;
}
/* .dropdown-content {
  -webkit-overflow-scrolling: touch;
} */
::-webkit-scrollbar {
  width: 0px;
}

#book-details {
  height: 100%;
  background: var(--my-color);
  padding: 0 30px;
  overflow: auto;
  box-shadow: -2px -3px -5px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  color: white;
}

.book-list li {
  display: flex;
  /* text-align: center; */
  margin: 0 0 0px 10px;
  background-color: white;
  /* box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3); */
  cursor: pointer;
  color: var(--my-color);
  transform: translateX(100%);
}
#filter-by-genre {
  margin: 0;
  padding: 0 20px;
  background-color: white;
  border-bottom: dotted 1px black;
}
#filter-by-genre select {
  margin: 0;
  border: none;
}

#grocery-and-recipe {
  overflow: hidden;
}
#grocery-list,
#recipe-list {
  height: 45%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.slide-book-left {
  animation-name: slideLeft;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}
@keyframes slideLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
.book-list li:hover {
  box-shadow: 0px 0px 5px inset rgba(0, 0, 0, 0.3);
}
.book-list li.selected {
  background: var(--my-color);
  color: white;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.5);
}
.booklist-text {
  width: 90%;
  /* margin: 0 5% 1px 15%; */
  padding: 10px 10px;
  border-bottom: dotted 1px black;
}
.book-list li.selected .booklist-text {
  color: white !important;
}

.completed {
  text-decoration: line-through;
}

#delete-book {
  background: inherit;
  border: none;
  color: inherit;
  cursor: pointer;
  overflow: hidden;
}

#edit-item {
  cursor: pointer;
  color: inherit;
  border: none;
  background: none;
}

#delete-book:hover,
#edit-item:hover {
  color: red;
}
.complete-button-clicked {
  animation-name: crossOut;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
@keyframes crossOut {
  0% {
    height: 16px;
    width: 5%;
    transform: rotate(0deg);
  }
  50% {
    height: 2px;
    width: 5%;
    background: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    transform: translate(0, 20px) rotate(180deg);
  }
  100% {
    width: 90%;
    height: 2px;
    background: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    transform: translate(20px, 20px) rotate(180deg);
  }
}
.delete-button-clicked {
  animation-name: fadeOut;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.book-details-container h5{
  text-align: center; 
  padding:5px;
  border-bottom: solid 1px rgba(255,255,255,0.5);
}
.book-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.book-details-header {
  border-bottom: solid 1px rgba(255,255,255,0.3);
}
.book-details-text ul {
  margin: 5px;
  font-weight: bold;
}

#amazon {
  /* display: none; */
  margin: 0;
  padding: 0;
}
/* #amazon > img {
  border-radius: 8px;
} */
#amazon > img:hover {
  box-shadow: 0 0 10px white;
}

.other-books {
  margin-left: 30px;
}

#add-button {
  position: absolute;
  color: var(--my-color);
  background-color: white;
  left: 50%;
  transform: translate(-50%, -50%);
  border: dashed 1px black;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  font-size: 20px;
  font-weight: bold;
  height: 30px;
  width: 30px;
  line-height: 0;
  padding: 0 0 4px 0;
  cursor: pointer;
}
#add-button:hover {
  box-shadow: 0 0 15px rgba(211, 170, 170, 0.6);
}

#add-forms {
  width: 700px;
  background-color: white;
  border-top: 1px dashed black;
  height: 20px;
  overflow: hidden;
}

.add-todos-container {
  width: 100%;
}

#show-grocery-form,
#show-recipe-form {
  width: 40%;
  margin: 10px 5%;
}

#add-books,
#add-authors,
#recipe-or-grocery {
  background: #fff;
  padding: 10px;
  margin: 20px 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
#add-authors {
  left: 0;
}
#ingredient-list > div {
  display: inline-block;
}
.ing {
  display: inline-block;
  border: dashed 1px black;
  padding: 4px 6px 4px 4px;
  margin: 5px 5px 0 0;
}
.remove-ing {
  transform: translate(-10px, 0);
  padding: 0 5px 2px 5px;
  display: inline-block;
  background-color: lightgray;
  cursor: pointer;
}
.remove-ing:hover {
  background-color: var(--my-color);
  color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.add-button-container {
  position: absolute;
  top: 10px;
  width: 46%;
  text-align: right;
}

#add-author {
  display: none;
}

#add-book-button {
  margin-top: 1.6rem;
}

#add-book-and-author-button {
  margin-top: 20px;
}

#book-autofill {
  background: white;
  padding: 10px;
  height: 120px;
  width: 100%;
  max-width: 250px;
  overflow-y: scroll;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.book-autofill {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 5px;
  border-bottom: dashed 1px black;
}
.book-autofill > div {
  grid-column: 2/4;
  cursor: pointer;
}
.book-autofill:hover {
  background: lightgray;
}

@media only screen and (max-width: 740px) {
  #main {
    width: 94%;
    padding: 0;
  }
  #add-forms {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .user-dashboard {
    display: block;
  }
  .stats-container,
  .sign-out {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  #books-and-details {
    display: inherit;
  }
  #grocery-and-recipe {
    height: 82%;
  }
  .book-list {
    height: 90%;
  }
  #grocery-list,
  #recipe-list {
    height: 45%;
  }
  #add-forms {
    grid-template-columns: 1fr;
  }
  #main h4#food-list-title {
    border-bottom: none;
  }

  h5.food-titles {
    border-radius: 10px 10px 0 0;
    background-color: lightgray;
    height: auto;
  }
  #book-details {
    border-radius: 10px 10px 0 0;
  }
  #show-grocery-form,
  #show-recipe-form {
    width: 80%;
    margin: 10px 10%;
  }
}

@media only screen and (max-width: 360px) {
  .datepicker-modal {
    min-width: auto !important;
  }
  .datepicker-table,
  .datepicker-footer,
  .datepicker-controls {
    width: 100% !important;
  }
  .logout-button {
    padding: 0 8px !important;
  }
  .logout-button > a > i.right {
    margin-left: 5px;
  }
}
@media only screen and (max-height: 500px) {
  #main h4 {
    padding-top: 5px;
  }
}
@media only screen and (max-height: 380px) {
  #main h4 {
    font-size: 24px;
  }
}

/* OVERRIDE MATERIALIZE CSS STUFF */
.row {
  margin-bottom: 10px !important;
}
.input-field {
  margin-bottom: 0;
}
.btn {
  background-color: var(--my-color);
}
select.browser-default {
  margin-top: 5px;
}

input[type="text"]:focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label,
.input-field .prefix.active {
  color: var(--my-color) !important;
}
.row .input-field input:focus,
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid var(--my-color) !important;
  box-shadow: 0 1px 0 0 var(--my-color) !important;
}

.input-field {
  margin-bottom: 0 !important;
}

.dropdown-content li > span {
  font-size: 14px !important;
  color: var(--my-color) !important;
}

.select-dropdown li.disabled > span {
  color: rgba(0, 0, 0, 0.3) !important;
}
.btn {
  background-color: var(--my-color) !important;
}

.datepicker-table td.is-today {
  color: var(--my-color) !important;
}
.datepicker-date-display,
.datepicker-table td.is-selected {
  background-color: var(--my-color) !important;
  color: #fff !important;
}

.datepicker-cancel,
.datepicker-clear,
.datepicker-today,
.datepicker-done {
  color: var(--my-color) !important;
}

.datepicker-modal {
  width: 100% !important;
  position: relative !important;
}
.modal-overlay {
  opacity: 0 !important;
}
[type="checkbox"]:checked + span:not(.lever):before {
  border-right: 2px solid var(--my-color) !important;
  border-bottom: 2px solid var(--my-color) !important;
}
